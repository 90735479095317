/** @format */

import { AxiosResponse, AxiosInstance } from 'axios';
import { RecommendationResponse } from './../interfaces/types';
import ApiService from './BaseApiService';

const resource = '/api/recommendation';

export class RecommenderService {
  instance: AxiosInstance = ApiService;

  getRecommendation(recoData: string, key: string): Promise<AxiosResponse<RecommendationResponse>> {
    // const url = `${resource}/?data=${recoData}&key=${key}`;
    const url = `${resource}/?data=${recoData}&x-use-grpc=false`;
    const headers = {
      headers: {
        'X-API-Key': key,
      },
    };
    const response = ApiService.get(url, headers);

    return response;
  }
}

export default new RecommenderService();
