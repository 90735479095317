/** @format */

import Vue from 'vue';
import VueGtag from 'vue-gtag';
//@ts-expect-error
import ImageUploader from 'vue-image-upload-resize';

import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';

import App from './App.vue';
import { truncate } from './plugins/filters';

import { config } from './config';
import {
  CCC_LANG_CONST,
  CCC_PROD_KEY,
  EVENT_TYPE,
  LANG_CCC_DICT,
  LANG_CCC_DICT_LAST_SEEN,
  LANG_CCC_DICT_SEARCH_SIMILAR,
  PLACEMENT_ENUM,
  WTYPE_CONST,
} from './assets/const';

Vue.config.productionTip = false;

Vue.prototype.$config = config; // set config to global scope

Vue.filter('truncate', truncate);

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
    env: process.env.VUE_ENVIRONMENT,
  },
});

Vue.use(ImageUploader);

const setConfigData = () => {
  let dataLayerResponse = {};
  let dataLayerResponseSearchSimilar = {};
  let dataLayerResponseLastSeen = {};

  let base64data = '';
  let base64dataSearchSimilar = '';
  let base64dataLastSeen = '';

  //@ts-ignore
  const dataLayerArr = window.dataLayer;
  //@ts-ignore
  const currentPageDataLayerObj = dataLayerArr.find((el) => el?.automl);

  switch (currentPageDataLayerObj?.automl.eventType) {
    case EVENT_TYPE.HOME_PAGE:
      dataLayerResponse = {
        ...currentPageDataLayerObj.automl,
        'placement': PLACEMENT_ENUM.FOR_YOU,
      };

      config.app.wtype = WTYPE_CONST.HOME_PAGE;
      config.app.listN = LANG_CCC_DICT[CCC_LANG_CONST].title;
      console.log(EVENT_TYPE.HOME_PAGE);
      break;
    case EVENT_TYPE.CATEGORY_PAGE:
      dataLayerResponse = {
        ...currentPageDataLayerObj.automl,
        'placement': PLACEMENT_ENUM.RECENTLY_VIEWED,
      };
      console.log(EVENT_TYPE.CATEGORY_PAGE);

      config.app.wtype = WTYPE_CONST.CATEGORY_PAGE;
      config.app.listN = LANG_CCC_DICT[CCC_LANG_CONST].title;
      break;
    case EVENT_TYPE.DETAIL_PAGE:
      dataLayerResponse = {
        ...currentPageDataLayerObj.automl,
        'placement': PLACEMENT_ENUM.OTHER_YOU_MAY_LIKE,
      };
      config.app.wtype = WTYPE_CONST.DETAIL_PAGE;
      config.app.listN = LANG_CCC_DICT[CCC_LANG_CONST].title;
      console.log(EVENT_TYPE.DETAIL_PAGE);
      break;
    case EVENT_TYPE.SEARCH_PAGE:
      dataLayerResponse = {
        'userInfo': { ...currentPageDataLayerObj.automl.userInfo },
        'eventType': EVENT_TYPE.HOME_PAGE,
        'placement': PLACEMENT_ENUM.FOR_YOU,
      };
      console.log(EVENT_TYPE.SEARCH_PAGE);
      config.app.wtype = WTYPE_CONST.SEARCH;
      config.app.listN = LANG_CCC_DICT[CCC_LANG_CONST].title;
      break;
    case EVENT_TYPE.ERROR_PAGE:
      dataLayerResponse = {
        ...currentPageDataLayerObj.automl,
        'eventType': EVENT_TYPE.HOME_PAGE,
        'placement': PLACEMENT_ENUM.FOR_YOU,
      };
      config.app.wtype = WTYPE_CONST.SEARCH;
      config.app.listN = LANG_CCC_DICT[CCC_LANG_CONST].title;
      console.log(EVENT_TYPE.ERROR_PAGE);
      break;
    case EVENT_TYPE.DEDICATED_PAGE:
      dataLayerResponse = {
        ...currentPageDataLayerObj.automl,
        'eventType': EVENT_TYPE.HOME_PAGE,
        'placement': PLACEMENT_ENUM.FOR_YOU,
      };
      dataLayerResponseSearchSimilar = {
        ...currentPageDataLayerObj.automl,
        'eventType': EVENT_TYPE.HOME_PAGE,
        'placement': PLACEMENT_ENUM.FOR_YOU_CVR,
      };
      dataLayerResponseLastSeen = {
        ...currentPageDataLayerObj.automl,
        'eventType': EVENT_TYPE.CATEGORY_PAGE,
        'placement': PLACEMENT_ENUM.RECENTLY_VIEWED,
        'productEventDetail': {
          'pageCategories': [
            {
              'categories': ['nowosci'],
            },
          ],
        },
      };
      config.app.wtype = WTYPE_CONST.FOR_YOU;
      config.app.listN = LANG_CCC_DICT[CCC_LANG_CONST].title;
      config.app.listNLastSeen = LANG_CCC_DICT_LAST_SEEN[CCC_LANG_CONST].title;
      config.app.listNLastSeenSub = LANG_CCC_DICT_LAST_SEEN[CCC_LANG_CONST].title2;
      config.app.listNSearchSimilar = LANG_CCC_DICT_SEARCH_SIMILAR[CCC_LANG_CONST].title;
      config.app.listNSearchSimilarSub = LANG_CCC_DICT_SEARCH_SIMILAR[CCC_LANG_CONST].title2;

      base64dataSearchSimilar = btoa(
        encodeURIComponent(JSON.stringify(dataLayerResponseSearchSimilar)),
      );
      base64dataLastSeen = btoa(encodeURIComponent(JSON.stringify(dataLayerResponseLastSeen)));
      config.app.recoDataSearchSimilar = base64dataSearchSimilar ? base64dataSearchSimilar : '';
      config.app.recoDataLastSeen = base64dataLastSeen ? base64dataLastSeen : '';

      console.log(EVENT_TYPE.DEDICATED_PAGE);
      break;
    case EVENT_TYPE.EMPTY_CART_PAGE:
      dataLayerResponse = {
        ...currentPageDataLayerObj.automl,
        'eventType': EVENT_TYPE.HOME_PAGE,
        'placement': PLACEMENT_ENUM.FOR_YOU,
      };
      config.app.wtype = WTYPE_CONST.EMPTY_CART;
      config.app.listN = LANG_CCC_DICT[CCC_LANG_CONST].title;
      console.log(EVENT_TYPE.EMPTY_CART_PAGE);
      break;
    default:
      console.log(`Event Type:  doesn't set in params! `);
  }

  base64data = btoa(encodeURIComponent(JSON.stringify(dataLayerResponse)));
  config.app.recoData = base64data ? base64data : '';

  // const key = params.get('key');
  const key = CCC_PROD_KEY;
  config.app.key = process.env.VUE_APP_KEY ? process.env.VUE_APP_KEY : key;

  const wiw = String(window.innerWidth);
  config.app.wiw = wiw ? wiw : '';
};

new Vue({
  i18n,
  vuetify,
  render: (h) => {
    return h(App);
  },
  beforeCreate() {
    const webSiteUrl = window.location.href;
    const webSiteUrlArr = webSiteUrl.split('/');
    const langFromUrl = webSiteUrlArr[3];
    config.app.lang = langFromUrl;

    setConfigData();
  },
}).$mount('#app');
