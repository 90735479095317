/** @format */

import { AppConfig, AppConfigItem, ApiConfigItem } from './interfaces/types';

class Config implements AppConfig {
  public api: ApiConfigItem = {
    bffUrl: process.env.VUE_APP_API_URL,
  };

  public app: AppConfigItem = {
    lang: '',
    key: '',
    wiw: '',
    listN: '',
    wtype: '',
    debug: process.env.VUE_APP_CFG_LoggingEnabled == 'true' ? true : false,
    recoData: '',
    env: process.env.VUE_APP_ENVIRONMENT ? process.env.VUE_APP_ENVIRONMENT : 'dev',
  };
}

export const config = new Config();
