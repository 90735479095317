/** @format */

import { Component, Vue } from 'vue-property-decorator';
import { YoshAnalyticsEventCategory, YoshAnalyticsAction } from '@/interfaces/types';

const imgFileRegex = /apng|avif|gif|jpg|jpeg|jfif|pjpeg|pjp|png|svg|webp|bmp|ico|cur|tif|tiff/g;
@Component({
  name: 'ProductCard',
  props: [
    'id',
    'productId',
    'position',
    'category',
    'brand',
    'variant',
    'listN',
    'wType',
    'title',
    'imageUrl',
    'siteUrl',
    'price',
    'salePrice',
    'windowParentOrigin',
  ],
})
export default class ProductCard extends Vue {
  checkImgSuffix(imageUrl: string) {
    const imageUrlFullStopArray = imageUrl.split('.');
    const lastFullStopTest = imageUrlFullStopArray[imageUrlFullStopArray.length - 1];
    if (lastFullStopTest.match(imgFileRegex)) {
      return true;
    } else return false;
  }
  get getImgUrl() {
    if (this.checkImgSuffix(this.$props.imageUrl)) {
      return this.$props.imageUrl;
    } else return `${process.env.VUE_APP_IMAGE_PROXY}${this.$props.imageUrl}`;
  }
  get priceAnalytics() {
    return this.$props.salePrice ? this.$props.salePrice : this.$props.price;
  }
  handleClickDataLayer() {
    const addDataLayer = {
      event: 'gaevent_new',
      category: 'Kliknicie produktu',
      action: this.$props.wType,
      ecommerce: {
        click: {
          currencyCode: 'PLN',
          actionField: {
            list: `Yosh / ${this.$props.listN} / ${this.$props.wType}`,
          },
          products: [
            {
              name: this.$props.title,
              id: this.$props.productId,
              price: this.$props.salePrice ? this.$props.salePrice : this.$props.price,
              brand: this.$props.brand,
              category: this.$props.category,
              variant: this.$props.variant,
              list: `Yosh / ${this.$props.listN} / ${this.$props.wType}`,
              position: this.$props.position,
            },
          ],
        },
      },
    };
    // top.postMessage(
    //   {
    //     addDataLayer: addDataLayer,
    //     type: 'click-prod',
    //     sessionData: {
    //       productList: `Yosh / ${this.$props.listN} / ${this.$props.wType}`,
    //       productPosition: this.$props.position,
    //     },
    //   },
    //   this.$props.windowParentOrigin,
    // );https://ccc.eu
  }

  deleteLastWord(str: string) {
    const lastIndex = str.lastIndexOf(' ');
    const newStr = str.substring(0, lastIndex);
    return newStr ? newStr : '';
  }
  recordGAevent(action: string, event: object) {
    this.$gtag.event(action, event);
  }
  recordClick(siteUrl: string): void {
    this.handleClickDataLayer();
    this.recordGAevent(YoshAnalyticsAction.ClickScrollArrow, {
      'event_category': YoshAnalyticsEventCategory.Engagement,
      'event_label': siteUrl,
    });
  }
}
