import HmacSHA256 from "crypto-js/hmac-sha256"
import Base64 from 'crypto-js/enc-base64';
import dayjs from 'dayjs';
// @ts-expect-error
import nonce from 'nonce-str';

import { AxiosResponse } from 'axios';
import { AuthResponse } from "./../interfaces/types"
import ApiService from "./BaseApiService";


const calculateEncodedHash = (message: string, key: string) => {
  const hmacHash = HmacSHA256(message, key);
  const encoded = Base64.stringify(hmacHash);
  return encoded;
}

const resource = "/api/security";

export class SecurityService {
  generateNonce = (length: number): string => nonce(length)

  getUnixTimestamp = (): number => dayjs().unix()

  calculateHash = (key: string, origin: string, timestamp: number, nonce: string): string => {
    if (typeof timestamp == 'undefined') timestamp = this.getUnixTimestamp();
    if (typeof nonce == 'undefined') nonce = this.generateNonce(32);

    const normalizedString = `${origin}|${timestamp}|${nonce}`;
    return calculateEncodedHash(normalizedString, key);
  }

  getAccessToken = (origin: string, timestamp: number, nonce: string, hashValue: string): Promise<AxiosResponse<AuthResponse>> => {
    const request = {
      "Origin": origin,
      "HashValue": hashValue,
      "Timestamp": timestamp,
      "Nonce": nonce
    }

    const response = ApiService.post(`${resource}/auth`, request);

    return response;
  }

}

export default new SecurityService()