/** @format */

// import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  breakpoint: {
    thresholds: {
      xs: 768,
      sm: 1024,
      md: 1280,
      lg: 1480,
    },
  },
});
