/** @format */

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// Ready translated locale messages
const messages = {
  en: {
    main: {
      missingRequiredParams: 'Service required parameters are missing',
      authError: 'Authentication error',
      commonError: "Ooops... didn't find any products.",
      searchSectionTitle: 'Search',
      resultSectionTitle:
        'Send us a photo with the products you are looking for and we will find similar products for you in our store.',
      resultSectionSubtitle: 'Get inspired by the pictures and enjoy shopping!',
      resultCategoryEmpty: 'There are no products matching the selected criteria.',
      resultProductGridTitle:
        'We have detected the following products in the picture, to see similar in our store click on the product.',
      searchSimilarNoData: "Unfortunately we didn't find similar products",
      sortingLabel: 'Sort by',
      sortingDefault: 'Default',
      sortingBrandAsc: 'Brand A-Z',
      sortingBrandDesc: 'Brand Z-A',
      sortingPriceAsc: 'Price ascending',
      sortingPriceDesc: 'Price descending',
    },
    picture: {
      defaultTitle: 'Search for similar product',
    },
    searchForm: {
      imageUploadDescription: 'Upload a photo or copy the photo URL e.g. from Instagram',
      selectImageFileButton: 'Upload picture',
      urlSearch: 'Enter URL of picture',
      missingFileText: 'File missing',
      wrongFileTypeText: 'Uploaded file is not an image file type',
      wrongFileSizeText: 'Uploaded file is too large',
      invalidUrl: 'Invalid URL',
    },
    searchOther: {
      title: 'We have detected products. Click on a product to search',
    },
    filtering: {
      brandText: 'Brand',
      colorText: 'Color',
      genderText: 'Gender',
      genderCodes: {
        unisex: 'Unisex',
        female: 'Female',
        male: 'Male',
        notSet: 'Unspecified',
      },
    },
  },
  pl: {
    main: {
      missingRequiredParams: 'Brak parametrów niezbędnych do uruchomienia usługi',
      authError: 'Błąd autoryzacji',
      commonError: 'Ups... nie wykryliśmy produktów.',
      searchSectionTitle: 'Wyszukaj po zdjęciu',
      resultSectionTitle:
        'Prześlij zdjęcie z produktami, których szukasz, a my znajdziemy dla Ciebie podobne produkty w naszym sklepie.',
      resultSectionSubtitle: '',
      resultCategoryEmpty: 'Brak produktów spełniających wybrane kryteria.',
      resultProductGridTitle: 'Wyniki',
      searchSimilarNoData: 'Niestety nie znaleźliśmy podobnych produktów',
      sortingLabel: 'Sortuj wg',
      sortingDefault: 'Domyślnie',
      sortingBrandAsc: 'Marka A-Z',
      sortingBrandDesc: 'Marka Z-A',
      sortingPriceAsc: 'Cena rosnąco',
      sortingPriceDesc: 'Cena malejąco',
    },
    picture: {
      defaultTitle: 'Wyszukaj podobny produkt',
    },
    searchForm: {
      imageUploadDescription:
        'Aby wyszukać produkty podobne do tego na zdjęciu, wprowadź link do zdjęcia lub wgraj zdjęcie z dysku.',
      selectImageFileButton: 'Wgraj zdjęcie z dysku',
      urlSearch: 'Wprowadź link do zdjęcia',
      missingFileText: 'Brak pliku',
      wrongFileTypeText: 'Wybrany plik nie jest plikiem graficznym',
      wrongFileSizeText: 'Wybrany plik jest za duży',
      invalidUrl: 'Niepoprawny adres URL',
    },
    searchOther: {
      title: 'Wykryliśmy produkty. Kliknij w produkt, aby wyszukać',
    },
    filtering: {
      brandText: 'Marka',
      colorText: 'Kolor',
      genderText: 'Płeć',
      genderCodes: {
        unisex: 'Unisex',
        female: 'Damskie',
        male: 'Męskie',
        notSet: 'Nieokreślona',
      },
    },
  },
  hu: {
    main: {
      missingRequiredParams: 'A szolgáltatás indításához hiányoznak a szükséges paraméterek',
      authError: 'Azonosítási hiba',
      commonError: 'Hoppá…nem található a termék!',
      searchSectionTitle: 'Keresés',
      resultSectionTitle:
        'A képkereséssel hasonló termékeket kereshet, feltölthet egy képet vagy megadhat egy URL címet',
      resultSectionSubtitle: '',
      resultCategoryEmpty: 'Nincs kiválasztott kritériumnak megfelelő termék.',
      resultProductGridTitle: 'Eredmények',
      searchSimilarNoData: 'Hoppá…nem található a termék. Válasszon egy másik képet!',
      sortingLabel: 'Szűrés',
      sortingDefault: 'Alapértelmezett',
      sortingBrandAsc: 'Márka A-Z',
      sortingBrandDesc: 'Márka Z-A',
      sortingPriceAsc: 'Ár - legolcsóbb elöl',
      sortingPriceDesc: 'Ár - legdrágább elöl',
    },
    picture: {
      defaultTitle: 'Keressen hasonló terméket',
    },
    searchForm: {
      imageUploadDescription: 'Másoljon be egy linket vagy töltsön fel egy képet',
      selectImageFileButton: 'Képkeresés',
      urlSearch: 'Adja meg az URL címet',
      missingFileText: 'A fájl nem található',
      wrongFileTypeText: 'A kiválasztott fájl nem kép',
      wrongFileSizeText: 'A kiválasztott fájl mérete túl nagy',
      invalidUrl: 'Hibás URL cím',
    },
    searchOther: {
      title:
        'Az alábbi termékeket találtuk a feltöltött kép alapján; Kattintson a kapcsolódó termékfotókra, hogy hasonló modelleket láthasson',
    },
    filtering: {
      brandText: 'Márka',
      colorText: 'Szín',
      genderText: 'Nemű',
      genderCodes: {
        unisex: 'Uniszex',
        female: 'Női',
        male: 'Férfi',
        notSet: 'Nem részletezett',
      },
    },
  },
};

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'pl', // set locale
  messages, // set locale messages
});
