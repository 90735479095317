/** @format */

export function loadConfigStyle(wiw: string) {
  const style = document.createElement('style');
  const wiwInt = parseInt(wiw);

  const addClassToAll = function (classNameStr: string, AddClassStr: string) {
    const cardEls = document.getElementsByClassName(classNameStr);
    const len = cardEls !== null ? cardEls.length : 0;
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        cardEls[i].className = `${classNameStr} ${AddClassStr}`;
      }
    }
  };

  if (wiwInt > 1100) {
    addClassToAll('y-card', 'y-cardXLg');
  } else if (wiwInt <= 1100 && wiwInt > 960) {
    addClassToAll('y-card', 'y-cardLg');
  } else if (wiwInt <= 960 && wiwInt > 760) {
    addClassToAll('y-card', 'y-cardMd');
  } else if (wiwInt <= 760 && wiwInt > 530) {
    addClassToAll('y-card', 'y-cardSm');
  } else {
    addClassToAll('y-card', 'y-cardXsm');
  }
  document.head.appendChild(style);
}
