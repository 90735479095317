/** @format */

import { ServiceFactoryList } from '@/interfaces/types';
import SecurityService from './SecurityService';
import BrowserStorageService from './BrowserStorageService';
import RecommenderService from './RecommenderService';

const services: ServiceFactoryList = {
  browserStorage: BrowserStorageService,
  security: SecurityService,
  recommender: RecommenderService
  // other
};

export const ServiceFactory = {
  get: (name: string) => services[name],
};
