/** @format */

export const EVENTS_TITLES_ARRAY: string[] = [
  'category-page-view',
  'detail-page-view',
  'search',
  '404-page-view',
  'dedicated-landing-page-view',
];

export enum WTYPE_CONST {
  FOR_YOU = 'for-you',
  ZERO_RESULTS = 'zeroresults',
  SEARCH = 'other',
  DETAIL_PAGE = 'product',
  CATEGORY_PAGE = 'category',
  HOME_PAGE = 'home',
  EMPTY_CART = 'emptycart',
}

export enum ENVIRONMENT_ENUM {
  DEV = 'dev',
  PROD = 'prod',
}

export enum DEV_URLS_ENUM {
  ERROR = 'error-404.html',
  CATEGORY = 'damskie.html',
  DETAILED = 'buty-sandaly.html',
  HOME = 'ccc-home',
  FOR_YOU = 'wybrane-dla-ciebie.html',
  SEARCH = 'search.html',
  EMPTY_CART = 'koszyk-lista.html',
}

export enum PLACEMENT_ENUM {
  // FOR_YOU = 'recommended-for-you',
  FOR_YOU = 'recommended-for-you-pl-cvr-rerank-3',
  OTHER_YOU_MAY_LIKE = 'ccc-others-you-may-like-pl-cvr-rerank-2',
  RECENTLY_VIEWED = 'recently_viewed_default',
  FOR_YOU_CVR = 'ccc-recommended-for-you-pl-cvr-1',
}

export enum EVENT_TYPE {
  HOME_PAGE = 'home-page-view',
  CATEGORY_PAGE = 'category-page-view',
  DETAIL_PAGE = 'detail-page-view',
  SEARCH_PAGE = 'search',
  ERROR_PAGE = '404-page-view',
  DEDICATED_PAGE = 'dedicated-landing-page-view',
  EMPTY_CART_PAGE = 'empty-shopping-cart-page-view',
}

export const CCC_LANG_CONST = 'pl';
export const CCC_PROD_KEY = 'AIzaSyBijoWzGvoz_gK5F22aiLeZqDo_LyPpaZg';
export const CCC_DEV_KEY = 'AIzaSyBsY-bAMfYXXCoG3E41q5Rc3SBx1g5roeA';
export const LANG_CCC_DICT = {
  pl: {
    title: 'Wybrane dla Ciebie',
  },
  cz: {
    title: 'Vyvoleno pro vás',
  },
  sk: {
    title: 'Vyvolené pre vás',
  },
};

export const LANG_CCC_DICT_LAST_SEEN = {
  pl: {
    title: 'Ostatnio oglądane',
    title2: 'OGLĄDANE',
  },
  cz: {
    title: 'OSTATNIO',
    title2: 'OGLĄDANE',
  },
  sk: {
    title: 'OSTATNIO',
    title2: 'OGLĄDANE',
  },
};

export const LANG_CCC_DICT_SEARCH_SIMILAR = {
  pl: {
    title: 'Sprawdź również',
    title2: 'DLA CIEBIE',
  },
  cz: {
    title: 'WYBRANE',
    title2: 'DLA CIEBIE',
  },
  sk: {
    title: 'WYBRANE',
    title2: 'DLA CIEBIE',
  },
};
