/** @format */

export interface AppConfig {
  api: ApiConfigItem;
  app: AppConfigItem;
}

export interface ApiConfigItem {
  bffUrl: string;
}

export interface AppConfigItem {
  lang: string;
  key: string;
  wiw: string;
  wtype: string;
  listN: string;
  debug: boolean;
  recoData: string;
  env: string;
  recoDataLastSeen?: string;
  listNLastSeen?: string;
  listNLastSeenSub?: string;
  recoDataSearchSimilar?: string;
  listNSearchSimilar?: string;
  listNSearchSimilarSub?: string;
}

// export interface RecoData {
//   userId: string;
//   eventType: string;
//   placement: string;
//   productId: string;
// }

export interface StringDict {
  [key: string]: string;
}

export interface AppLoader {
  style: string;
  color: string;
  isFullPage: boolean;
  loading: boolean;
}

export interface AppInitState {
  loaded: boolean;
  error: boolean;
  errorMessage: string;
}

export interface AppAuthData {
  data: AuthResponse;
}

export interface AuthResponse {
  tokenType?: string;
  accessToken?: string;
  expiresIn?: number;
}

export interface AppState {
  error: boolean;
  errorMessage: string;
  retryFlag: number;
}

export interface ServiceFactoryList {
  [key: string]: object;
}

export interface RecommendationData {
  recommendationInvoked: boolean;
  recommendationInvokedLastSeen?: boolean;
  recommendationInvokedSearchSimilar?: boolean;
  productList: Array<RecommendationProductItem> | null;
  productListSearchSimilar?: Array<RecommendationProductItem> | null;
  productListLastSeen?: Array<RecommendationProductItem> | null;
}

export interface RecommendationResponse {
  recommendationToken: string;
  dryRun: boolean;
  products: Array<RecommendationProductItem>;
}

export interface RecommendationProductItem {
  id: number;
  productId: string;
  title: string;
  description: string;
  productSiteUrl: string;
  imageUrl: string;
  price: string;
  salePrice: string;
  brand: string;
  category: string;
  color: string;
}

export enum YoshAnalyticsEventCategory {
  Engagement = 'engagement',
}

export enum YoshAnalyticsAction {
  SearchSimilarByExternalUrl = 'search_similar_by_external_url',
  ClickScrollArrow = 'click_scroll_arrow',
  SelectProduct = 'select_product',
  OpenProductPage = 'open_product_page',
}
