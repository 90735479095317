/** @format */

export class BrowserStorageService {
  check() {
    try {
      sessionStorage.getItem('check');
      return true;
    } catch {
      return false;
    }
  }

  get(key: string) {
    return sessionStorage.getItem(key);
  }

  set(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }
}

export default new BrowserStorageService();
