/** @format */

import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyA4h52KE0e2ESj3lh93hW4Yt7nyqE2_uDs',
  authDomain: 'ccc-reco-ai-prod-pl.firebaseapp.com',
  projectId: 'ccc-reco-ai-prod-pl',
  storageBucket: 'ccc-reco-ai-prod-pl.appspot.com',
  messagingSenderId: '789561916690',
  appId: '1:789561916690:web:b7c46324ca1cebf20007cb',
  measurementId: 'G-PBZEBDZC0H',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
