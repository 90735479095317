/** @format */

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { StringDict, YoshAnalyticsEventCategory, YoshAnalyticsAction } from '@/interfaces/types';

import ScrollRightIcon from './../Icons/ScrollRightIcon.vue';
import ScrollLeftIcon from './../Icons/ScrollLeftIcon.vue';

@Component({
  name: 'Carousel',
  components: {
    ScrollLeftIcon,
    ScrollRightIcon,
  },
})
export default class Carousel extends Vue {
  @Prop() numberOfProducts?: number;

  labels: StringDict = {};

  numberOfElementsMax = 6;
  buttonWidth = 10;
  showLeftButton = false;
  showRightButton = false;

  get productsNumber(): number {
    return this.numberOfProducts ? this.numberOfProducts : 0;
  }

  @Watch('productsNumber')
  productsChanged() {
    this.setCarousel();
  }

  // @Watch('showLeftButton')
  // leftChanged() {
  //     console.log('left button flag changed')
  // }

  // @Watch('showRightButton')
  // rightChanged() {
  //     console.log('right button flag changed')
  // }

  setCarousel(): void {
    if (this.productsNumber > this.numberOfElementsMax) {
      this.showRightButton = true;
    }
  }

  recordGAevent(action: string, event: object) {
    this.$gtag.event(action, event);
  }

  changeSlide(delta: number): void {
    // delta -1 is left click
    // delta 1 is right click
    const gtagText = delta === -1 ? 'left click' : 'right click';

    this.recordGAevent(YoshAnalyticsAction.ClickScrollArrow, {
      'event_category': YoshAnalyticsEventCategory.Engagement,
      'event_label': gtagText,
    });
    this.showLeftButton = true;
    const carousel = this.$el.querySelector('.y-carousel') as HTMLElement;

    if (carousel) {
      const offsetWidth = carousel.offsetWidth;
      const scrollLeft = carousel.scrollLeft - this.buttonWidth;
      const position = scrollLeft + offsetWidth * delta;

      this.scrollElementTo(carousel, position).then(() => {
        this.hideSlideIcons(carousel, delta, position);
      });
    }
  }

  private hideSlideIcons(carousel: HTMLElement, delta: number, position: number) {
    const carouselPadding = 25;

    let notFirstSlide = false;
    if (position > this.buttonWidth) {
      notFirstSlide = false;
    } else {
      notFirstSlide = true;
    }

    const actualEnd = carousel.scrollLeft + carousel.offsetWidth + carouselPadding;

    this.showLeftButton = notFirstSlide && delta === -1 ? false : true;
    this.showRightButton = carousel.scrollWidth <= actualEnd && delta === 1 ? false : true;
  }

  private scrollElementTo(el: HTMLElement, position: number) {
    return new Promise<void>((resolve): void => {
      const scrollListener = (evt: Event) => {
        if (typeof evt === 'undefined') {
          return;
        }

        const target = evt.currentTarget as HTMLElement;

        window.setTimeout(() => {
          target.removeEventListener('scroll', scrollListener);
          resolve();
        }, 500);
      };

      el.addEventListener('scroll', scrollListener);

      el.scrollTo(position, 0);
    });
  }
}
