/** @format */

import { Component, Vue } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { Retrier } from '@jsier/retrier';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import './App-theme.scss';
import { loadConfigStyle } from './style-config/style';

import {
  StringDict,
  AppLoader,
  AppConfig,
  AppInitState,
  AppState,
  AppAuthData,
  RecommendationData,
  RecommendationResponse,
  RecommendationProductItem,
  YoshAnalyticsEventCategory,
  YoshAnalyticsAction,
} from './interfaces/types';

import Error from './components/Error/Error.vue';
import ProductCard from './components/ProductCard/ProductCard.vue';
import Carousel from './components/Carousel/Carousel.vue';

import { ServiceFactory } from './services/ServiceFactory';
import { RecommenderService } from './services/RecommenderService';
import '@/firebaseinit.ts';
import { WTYPE_CONST } from './assets/const';

const recommenderService = ServiceFactory.get('recommender') as RecommenderService;

//@ts-expect-error
@Component({
  components: {
    Loading,
    Error,
    Carousel,
    ProductCard,
  },
})
export default class App extends Vue {
  appLabels: StringDict = {}; // loaded in mounted

  appLoader: AppLoader = {
    style: 'bars',
    color: 'var(--sub-main-color)',
    isFullPage: false,
    loading: false,
  };

  dataLayerWType = this.config.app.wtype;
  windowParentOrigin = 'https://ccc.eu/pl/';

  initState: AppInitState = {
    loaded: false,
    error: false,
    errorMessage: '',
  };

  auth: AppAuthData = {
    data: {},
  };

  app: AppState = {
    error: false,
    errorMessage: '',
    retryFlag: 0,
  };

  recommendation: RecommendationData = {
    recommendationInvoked: false,
    recommendationInvokedLastSeen: false,
    recommendationInvokedSearchSimilar: false,
    productList: null,
    productListSearchSimilar: null,
    productListLastSeen: null,
  };

  get config() {
    // @ts-expect-error
    return this.$config as AppConfig;
  }

  get authToken() {
    if (this.auth.data.tokenType && this.auth.data.accessToken) {
      return `${this.auth.data.tokenType} ${this.auth.data.accessToken}`;
    } else {
      return '';
    }
  }

  get isLoading() {
    return this.appLoader.loading;
  }

  get recommendedProducts() {
    if (this.recommendation.productList != null) {
      const fp: Array<RecommendationProductItem> = this.recommendation.productList;

      return fp;
    }

    return [];
  }
  get recommendedProductsSearchSimilar() {
    if (this.recommendation.productListSearchSimilar != null) {
      const fp: Array<RecommendationProductItem> = this.recommendation.productListSearchSimilar;

      return fp;
    }

    return [];
  }
  get recommendedProductsLastSeen() {
    if (this.recommendation.productListLastSeen != null) {
      const fp: Array<RecommendationProductItem> = this.recommendation.productListLastSeen;

      return fp;
    }

    return [];
  }

  get listName() {
    return this.config.app.listN;
  }
  get wType() {
    return this.dataLayerWType;
  }

  mounted(): void {
    this.appLabels = {
      missingRequiredParams: this.$t('main.missingRequiredParams').toString(),
      authError: this.$t('main.authError').toString(),
      commonError: this.$t('main.commonError').toString(),
      searchSectionTitle: this.$t('main.searchSectionTitle').toString(),
      searchSimilarNoData: this.$t('main.searchSimilarNoData').toString(),
      resultSectionTitle: this.$t('main.resultSectionTitle').toString(),
      resultSectionSubtitle: this.$t('main.resultSectionSubtitle').toString(),
      resultCategoryEmpty: this.$t('main.resultCategoryEmpty').toString(),
      resultProductGridTitle: this.$t('main.resultProductGridTitle').toString(),
      sortingLabel: this.$t('main.sortingLabel').toString(),
      sortingDefault: this.$t('main.sortingDefault').toString(),
      sortingBrandAsc: this.$t('main.sortingBrandAsc').toString(),
      sortingBrandDesc: this.$t('main.sortingBrandDesc').toString(),
      sortingPriceAsc: this.$t('main.sortingPriceAsc').toString(),
      sortingPriceDesc: this.$t('main.sortingPriceDesc').toString(),

      filteringBrandText: this.$t('filtering.brandText').toString(),
      filteringColorText: this.$t('filtering.colorText').toString(),
      filteringGenderText: this.$t('filtering.genderText').toString(),
    };

    this.init();

    if (!this.config.app.key) {
      this.setAuthError(this.appLabels.missingRequiredParams);
    }

    if (window.addEventListener) {
      window.addEventListener('message', this.parentMessageHandler, false);
    }
  }

  updated(): void {
    loadConfigStyle(this.config.app.wiw);
  }

  // event handler
  parentMessageHandler(evt: any) {
    this.log('parentMessageHandler invoked');
    if (evt.data.wiw) {
      loadConfigStyle(evt.data.wiw);
    }
  }

  // event handler
  getRecommendationHandler(recoData: string, key: string): void {
    this.clearAppError();
    this.invokeGetRecommendation(recoData, key);
  }
  getRecommendationHandlerSearchSimilar(recoData: string, key: string): void {
    this.clearAppError();
    this.invokeGetRecommendationSearchSimilar(recoData, key);
  }
  getRecommendationHandlerLastSeen(recoData: string, key: string): void {
    this.clearAppError();
    this.invokeGetRecommendationLastSeen(recoData, key);
  }

  //event handler (click on product card)
  recordProductClick(product: RecommendationProductItem): void {
    this.log('recordProductClick invoked');

    this.recordGAevent(YoshAnalyticsAction.OpenProductPage, {
      'event_category': YoshAnalyticsEventCategory.Engagement,
      'event_label': product.productSiteUrl,
    });
  }

  changeCategoryName(categoryName: string) {
    return categoryName.replaceAll(' > ', '/');
  }

  init(): void {
    this.log('init invoked');
    this.resetInitState();
    this.clearAuthError();

    const recoData = this.config.app.recoData;
    const recoDataSearchSimilar = this.config.app.recoDataSearchSimilar;
    const recoDataLastSeen = this.config.app.recoDataLastSeen;
    const key = this.config.app.key;

    this.getRecommendationHandler(recoData, key);

    // this.fetchRecommendedProducts();
    if (this.config.app.wtype == WTYPE_CONST.FOR_YOU && recoDataSearchSimilar && recoDataLastSeen) {
      // this.fetchRecommendedProductsDataLastSeen();
      // this.fetchRecommendedProductsDataSearchSimilar();
      this.getRecommendationHandlerSearchSimilar(recoDataSearchSimilar, key);
      this.getRecommendationHandlerLastSeen(recoDataLastSeen, key);
    }

    // http://localhost:8080/?ck=cos&o=cos&uid=1303181126.1612180304&productid=5903419754074&placement=ccc-others-you-may-like-pl-cvr-1&eventtype=detail-page-view&key=AIzaSyB2kNuozQNQQt4y-7SDoBMEdsRTysn162E
    this.endInit(true);
  }

  // async fetchRecommendedProducts() {
  //   const url = `https://latest---reco-backend-mzcvixjn7q-ew.a.run.app/api/recommendation?data=${this.config.app.recoData}`;
  //   const data = await axios.get<any[]>(url);
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //   //@ts-ignore
  //   this.recommendedProducts = data.data.products;
  // }
  // async fetchRecommendedProductsDataLastSeen() {
  //   const url = `https://latest---reco-backend-mzcvixjn7q-ew.a.run.app/api/recommendation?data=${this.config.app.recoDataLastSeen}`;
  //   const data = await axios.get<any[]>(url);
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //   //@ts-ignore
  //   this.recommendedProductsLastSeen = data.data.products;
  // }
  // async fetchRecommendedProductsDataSearchSimilar() {
  //   const url = `https://latest---reco-backend-mzcvixjn7q-ew.a.run.app/api/recommendation?data=${this.config.app.recoDataSearchSimilar}`;
  //   const data = await axios.get<any[]>(url);
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //   //@ts-ignore
  //   this.recommendedProductsSearchSimilar = data.data.products;
  // }

  invokeGetRecommendation(recoData: string, key: string): void {
    this.log('invokeGetRecommendation invoked');
    this.startLoading();

    const retrier = new Retrier({
      limit: 3,
      delay: 1000,
      stopRetryingIf: (error) => error.response?.status === 401,
    });

    retrier
      .resolve(() => recommenderService.getRecommendation(recoData, key))
      .then(
        (result) => {
          this.handleGetRecommendation(result.data);
          // this.recordResultToCache(0, result.data, true);
        },
        (error) => {
          this.handleSearchSimilarError(error, 'f');
        },
      )
      .finally(() => {
        this.endLoading();
      });
  }

  invokeGetRecommendationSearchSimilar(recoData: string, key: string): void {
    this.log('invokeGetRecommendationSearchSimilar invoked');
    this.startLoading();

    const retrier = new Retrier({
      limit: 3,
      delay: 1000,
      stopRetryingIf: (error) => error.response?.status === 401,
    });

    retrier
      .resolve(() => recommenderService.getRecommendation(recoData, key))
      .then(
        (result) => {
          this.handleGetRecommendationSearchSimilar(result.data);
          // this.recordResultToCache(0, result.data, true);
        },
        (error) => {
          this.handleSearchSimilarError(error, 'f');
        },
      )
      .finally(() => {
        this.endLoading();
      });
  }

  invokeGetRecommendationLastSeen(recoData: string, key: string): void {
    this.log('invokeGetRecommendationLastSeen invoked');
    this.startLoading();

    const retrier = new Retrier({
      limit: 3,
      delay: 1000,
      stopRetryingIf: (error) => error.response?.status === 401,
    });

    retrier
      .resolve(() => recommenderService.getRecommendation(recoData, key))
      .then(
        (result) => {
          this.handleGetRecommendationLastSeen(result.data);
          // this.recordResultToCache(0, result.data, true);
        },
        (error) => {
          this.handleSearchSimilarError(error, 'f');
        },
      )
      .finally(() => {
        this.endLoading();
      });
  }

  resetInitState(): void {
    this.log('resetInitState invoked');
    this.setLoading(false);
    this.initState.loaded = false;
  }

  startLoading(): void {
    this.log('startLoading invoked');
    this.setLoading(true);
  }

  endLoading(): void {
    this.log('endLoading invoked');
    this.setLoading(false);
  }

  setLoading(state: boolean): void {
    this.appLoader.loading = state;
  }

  endInit(success: boolean): void {
    this.log('endInit invoked');
    this.initState.loaded = success;
  }

  clearAuthError(): void {
    this.log('clearAuthError invoked');
    this.initState.error = false;
    this.initState.errorMessage = '';
  }

  setAuthError(errorMessage: string): void {
    this.log('setAuthError invoked');
    this.initState.error = true;
    this.initState.errorMessage = errorMessage;
  }

  clearAppError(): void {
    this.log('clearAppError invoked');
    this.app.error = false;
    this.app.errorMessage = '';
  }

  setAppError(errorMessage: string): void {
    this.log('setAppError invoked');
    this.app.error = true;
    this.app.errorMessage = errorMessage;
  }

  handleGetRecommendation(data: RecommendationResponse): void {
    this.log('handleGetRecommendation invoked');
    this.recommendation.recommendationInvoked = true;
    this.recommendation.productList = data.products;
    this.handleDataLayer(data);
    this.resetRetryFlag();
  }

  handleGetRecommendationSearchSimilar(data: RecommendationResponse): void {
    this.log('handleGetRecommendation invoked');
    this.recommendation.recommendationInvokedSearchSimilar = true;
    this.recommendation.productListSearchSimilar = data.products;
    this.handleDataLayer(data);
    this.resetRetryFlag();
  }

  handleGetRecommendationLastSeen(data: RecommendationResponse): void {
    this.log('handleGetRecommendation invoked');
    this.recommendation.recommendationInvokedLastSeen = true;
    this.recommendation.productListLastSeen = data.products;
    this.handleDataLayer(data);
    this.resetRetryFlag();
  }

  handleDataLayer(data: RecommendationResponse) {
    const addDataLayer = {
      event: 'gaevent_new',
      category: 'Doładowanie produktów',
      action: this.dataLayerWType,
      ecommerce: {
        currencyCode: 'PLN',
        impressions: [],
      },
    };
    if (data.products) {
      let item = {
        name: '',
        id: '',
        price: '',
        brand: '',
        category: '',
        variant: '',
        list: '',
        position: 0,
      };
      for (const [index, prod] of data.products.entries()) {
        item['name'] = prod.title;
        item['id'] = prod.productId;
        item['price'] = prod.salePrice ? prod.salePrice : prod.price;
        item['brand'] = prod.brand;
        item['category'] = this.changeCategoryName(prod.category);
        item['variant'] = prod.color;
        item['list'] = `Yosh / ${this.listName} / ${this.dataLayerWType}`;
        item['position'] = index + 1;
        //@ts-expect-error
        addDataLayer['ecommerce']['impressions'].push(item);
        item = {
          name: '',
          id: '',
          price: '',
          brand: '',
          category: '',
          variant: '',
          list: '',
          position: 0,
        };
      }
    }
    // top.postMessage(
    //   {
    //     addDataLayer: addDataLayer,
    //     type: 'add-all-prods',
    //   },
    //   this.windowParentOrigin,
    // );
  }

  resetRetryFlag(): void {
    this.app.retryFlag = 0;
  }

  handleSearchSimilarError(error: AxiosError, mode: string): void {
    this.log('handleSearchSimilarError invoked');
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      this.log('response error');
      switch (error.response.status) {
        case 401:
          this.setAppError(this.appLabels.commonError);
          break;
        default:
          this.resetRetryFlag();
          this.setAppError(this.appLabels.commonError);
          break;
      }
    } else if (error.request) {
      this.log('request error');
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      // console.log(error.request);
    } else {
      this.log('no request error');
      // Something happened in setting up the request that triggered an Error
      // console.log('Error', error.message);
    }
  }

  recordGAevent(action: string, event: object) {
    this.log('recordGAevent invoked');
    this.$gtag.event(action, event);
  }

  log(value: any): void {
    if (this.config.app.debug) {
      console.log(value);
    }
  }
}
